import { jsx, css } from "@emotion/react"
import React, { Suspense, useRef } from "react"
import { Canvas, useFrame } from "@react-three/fiber"

import { TestShader1 } from "./shaders/TestShader1"
import { OrbitControls, PerspectiveCamera } from "@react-three/drei"
import gsap from "gsap"
import * as THREE from "three"
import {
    ChromaticAberration,
    EffectComposer,
    Grid,
    HueSaturation,
    HueSaturationm,
    Pixelation,
} from "@react-three/postprocessing"
import { BlendFunction } from "postprocessing"

var randomisePosition = new THREE.Vector2(1, 2)

function rgb(r, g, b) {
    return new THREE.Vector3(r, g, b)
}

const segmentsResolution = 45

const baseColors = {
    pink: rgb(150, 220, 255),
    brightPink: rgb(250, 230, 245),
    darkPurple: rgb(195, 180, 255),
    orange: rgb(250, 230, 245),
}

const uniforms = {
    u_bg: { type: "v3", value: baseColors.pink },
    u_bgMain: { type: "v3", value: baseColors.brightPink },
    u_color1: { type: "v3", value: baseColors.darkPurple },
    u_color2: { type: "v3", value: baseColors.orange },
    u_time: { type: "f", value: 0 },
    u_randomisePosition: { type: "v2", value: randomisePosition },
}
const R = function (x, y, t) {
    return Math.floor(192 + 64 * Math.cos((x * x - y * y) / 300 + t))
}

const G = function (x, y, t) {
    return Math.floor(
        192 +
            64 *
                Math.sin(
                    (x * x * Math.cos(t / 4) + y * y * Math.sin(t / 3)) / 300
                )
    )
}

const B = function (x, y, t) {
    return Math.floor(
        192 +
            64 *
                Math.sin(
                    5 * Math.sin(t / 9) +
                        ((x - 100) * (x - 100) + (y - 100) * (y - 100)) / 1100
                )
    )
}

export const ShaderGrad = ({ position }) => {
    const shaderMaterialRef = useRef()
    let t = 0
    let j = 0
    let x = gsap.utils.random(0, 32)
    let y = gsap.utils.random(0, 32)
    let vCheck = false

    useFrame(({ clock }) => {
        shaderMaterialRef.current.uniforms.u_randomisePosition.value =
            new THREE.Vector2(j, j)
        shaderMaterialRef.current.uniforms.u_color1.value = new THREE.Vector3(
            R(x, y, t / 2),
            G(x, y, t / 2),
            B(x, y, t / 2)
        )

        shaderMaterialRef.current.uniforms.u_time.value = t
        if (t % 0.1 === 0) {
            if (vCheck === false) {
                x -= 1
                if (x <= 0) {
                    vCheck = true
                }
            } else {
                x += 1
                if (x >= 32) {
                    vCheck = false
                }
            }
        }
        // Increase t by a certain value every frame
        j = j + 0.01
        t = t + 0.01
    })

    return (
        <mesh
            position={position}
            rotation={[
                THREE.MathUtils.degToRad(0),
                THREE.MathUtils.degToRad(0),
                THREE.MathUtils.degToRad(90),
            ]}
        >
            <planeGeometry
                attach="geometry"
                args={[
                    200,
                    window.innerWidth / 2,
                    segmentsResolution,
                    segmentsResolution,
                ]}
            />
            <shaderMaterial
                attach="material"
                vertexShader={TestShader1.vertexShader}
                fragmentShader={TestShader1.fragmentShader}
                uniforms={uniforms}
                ref={shaderMaterialRef}
            />
        </mesh>
    )
}

export function Grad(props) {
    const cam = useRef()

    return (
        <group scale={0.2}>
            <ShaderGrad position={[0, 0, -10]} faceResolution={100} />
        </group>
    )
}
