import {
    Box,
    Decal,
    Dodecahedron,
    Float,
    Icosahedron,
    PerspectiveCamera,
    RenderTexture,
    Text,
} from "@react-three/drei"
import { config } from "../config"
import { useFrame } from "@react-three/fiber"
import { useRef, useState } from "react"
import { easing } from "maath"
import { useNavigate } from "react-router-dom"

function Label({ text, ...props }) {
    const textRef = useRef()

    return (
        <Decal
            position={[0, 0, 0.75]}
            rotation={[-0.4, Math.PI, 0]}
            scale={[0.9, 0.25, 1]}
        >
            <meshStandardMaterial
                roughness={0.6}
                transparent
                polygonOffset
                polygonOffsetFactor={-10}
            >
                <RenderTexture attach="map" anisotropy={16}>
                    <PerspectiveCamera
                        makeDefault
                        manual
                        aspect={0.9 / 0.22}
                        position={[0, 0, 5]}
                    />
                    <color attach="background" args={["#ffffff"]} />

                    <ambientLight intensity={0.5} />
                    <directionalLight position={[10, 10, 5]} />
                    <Text
                        characters="abcdefghijklmnopqrstuvwxyz0123456789!"
                        rotation={[0, Math.PI, 0]}
                        ref={textRef}
                        fontSize={4}
                        color="black"
                        font="/3D/Geometria-Bold.ttf"
                    >
                        {text}
                    </Text>
                </RenderTexture>
            </meshStandardMaterial>
        </Decal>
    )
}

function Movement({ children }) {
    return (
        <Float
            speed={3} // Animation speed, defaults to 1
            rotationIntensity={0} // XYZ rotation intensity, defaults to 1
            floatIntensity={0.1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
            floatingRange={[-1, 1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
        >
            {children}
        </Float>
    )
}

function ShapeLink({ type, label, url, ...props }) {
    const ref = useRef()
    const nav = useNavigate()
    const [hovered, hover] = useState(false)
    const [detail, setDetail] = useState(null)
    const pointerOver = (e) => (e.stopPropagation(), hover(true))
    const pointerOut = () => hover(false)
    const pointerDown = () => {
        nav(url)
    }
    useFrame((state, delta) => {
        easing.damp3(ref.current.scale, hovered ? 0.8 : 0.7, 0.1, delta)
        easing.damp(
            ref.current.material,
            "radius",
            hovered ? 0.25 : 0.1,
            0.2,
            delta
        )
        easing.damp(ref.current.material, "zoom", hovered ? 1 : 1.2, 0.1, delta)
    })

    const contents = (
        <>
            <meshPhysicalMaterial
                metalness={0}
                roughness={0}
                color={config.colors.themeDark}
                args={[{ metalness: 0, roughness: 0 }]}
            />
            <Label text={label} />
        </>
    )
    let shape = null
    if (type === "ico") {
        shape = (
            <Icosahedron
                onPointerOver={pointerOver}
                onPointerOut={pointerOut}
                onPointerDown={pointerDown}
                ref={ref}
                {...props}
            >
                {contents}
            </Icosahedron>
        )
    } else {
        shape = (
            <Box
                onPointerOver={pointerOver}
                onPointerOut={pointerOut}
                onPointerDown={pointerDown}
                ref={ref}
                {...props}
            >
                {contents}
            </Box>
        )
    }
    return <Movement>{shape}</Movement>
}

export function Links() {
    return (
        <>
            <ShapeLink
                position={[0, -2, 0]}
                scale={0.4}
                args={[1, 0]}
                label={"Contact"}
                type={"ico"}
                url="/contact"
            />

            <ShapeLink
                type="box"
                scale={0.8}
                rotation={[0, 0, 0]}
                position={[0, 0, 0]}
                args={[1.5, 1.5]}
                label="About"
                url="/about"
            />

            <ShapeLink
                type={"ico"}
                position={[0, 2, 0]}
                scale={0.8}
                args={[1, 20]}
                label="Portfolio"
                url="/portfolio"
            />
        </>
    )
}
