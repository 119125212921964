import React, { useEffect, useState } from "react"

function resizeValue() {
    if (window.innerWidth < 768) {
        return "small"
    } else if (window.innerWidth < 1000) {
        return "mid"
    } else {
        return "large"
    }
}
export default function useResizer() {
    const [size, setSize] = useState(resizeValue())
    useEffect(() => {
        function handleResize() {
            setSize(resizeValue())
        }
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    })

    return size
}
