import Me from "../images/me.jpg"
import useResizer from "./hooks/Resizer"

export function About() {
    const size = useResizer()
    return (
        <div
            style={{
                display: "flex",
                flexDirection: size === "small" ? "column" : "row",

                width: "100vw",
                gap: "15px",
                backgroundColor: "black",
                color: "white",
            }}
        >
            <div style={{ width: "50vw" }}>
                <img
                    style={{
                        width: size === "small" ? "100vw" : "50vw",
                    }}
                    src={Me}
                    alt="me"
                />
            </div>
            <div
                style={{
                    backgroundColor: "black",
                    color: "white",
                    paddingTop: size === "small" ? "10px" : "150px",
                    paddingRight: size === "small" ? "20px" : "50px",
                    paddingLeft: size === "small" ? "20px" : "0",
                }}
            >
                <p>
                    William Harford is an accomplished technology executive with
                    a proven track record in leading engineering teams and
                    driving innovative payment solutions. With a strong
                    background in the tech industry, William has held key
                    leadership roles at prominent companies.
                </p>
                <p>
                    Previously, William served as the Head of Engineering at
                    PartnerStack, where he played a pivotal role in building and
                    expanding the engineering department, fostering a
                    collaborative and innovative work environment. Under his
                    leadership, PartnerStack achieved remarkable milestones and
                    delivered exceptional products, establishing itself as a
                    leader in the partner management platform space.
                </p>
                <p>
                    Prior to his role at PartnerStack, William served as the
                    Director of Payments at Wave Apps, a renowned financial
                    software company. During his tenure, he spearheaded the
                    development and implementation of cutting-edge payment
                    systems, ensuring efficiency, security, and regulatory
                    compliance. His expertise and leadership contributed
                    significantly to the success and growth of Wave Apps.
                </p>
                <p>
                    At MetaVera, William Harford led the technical team during
                    the company's acquisition by Enterprise Rent-A-Car,
                    successfully facilitating the integration of MetaVera's
                    in-vehicle technology solutions. He also served as the
                    Security Officer, ensuring PCI-DSS compliance and
                    safeguarding credit card transactions.
                </p>
                <p>
                    With a wealth of experience in engineering leadership,
                    payment systems, navigating acquisitions, and ensuring
                    regulatory compliance, William Harford brings valuable
                    expertise to his role as the Chief Technology Officer of
                    Prospecer. He is dedicated to driving innovation, delivering
                    exceptional results, and leveraging technology to propel
                    Prospecer's success in the ever-evolving digital landscape.
                </p>
            </div>
        </div>
    )
}
