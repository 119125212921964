export const config = {
    colors: {
        black: "#000000",
        themeLight: "#C5D86D",
        themeDark: "#D7263D",
        white: "#ffffff",
        themeMain: "#000000",
        themeMain2: "#555555",
        netural: "#AAAAAA",
    },
}
