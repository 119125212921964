import React from "react"
import { useScroll } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"

export default function Rotate(props) {
    const mesh = props.mesh
    const data = useScroll()

    useFrame(() => {
        const a = data.range(0, 1)

        if (props.fromScroll) {
            mesh.current.rotation.y = a * -5
        } else {
            mesh.current.rotation.y = mesh.current.rotation.y += 0.003
            mesh.current.rotation.z = mesh.current.rotation.z += 0.002
            mesh.current.rotation.x = mesh.current.rotation.x += 0.001
        }
    })

    return null
}
