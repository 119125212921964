// https://cydstumpel.nl/

import * as THREE from "three"
import { Suspense, useEffect, useRef, useState } from "react"
import { Canvas, useFrame, useThree } from "@react-three/fiber"
import {
    Image,
    Environment,
    ScrollControls,
    useScroll,
    useTexture,
    Html,
    GradientTexture,
    Text,
    Icosahedron,
} from "@react-three/drei"
import { easing } from "maath"
import "./extenders/Plane"
import { config } from "../config"
import { Ball } from "./Ball"
import Loader from "./Loader"
import useResizer from "./hooks/Resizer"

export function Portfilo(props) {
    const size = useResizer()
    return (
        <div
            style={{
                position: "absolute",
                width: "100vw",
                height: "100vh",
            }}
        >
            <h3
                style={{
                    position: "fixed",
                    zIndex: "9999",
                    top: "100px",
                    color: config.colors.white,
                    width: "100vw",
                    margins: "auto",
                    textAlign: "center",
                }}
            >
                <div style={{}}>things we have done</div>
            </h3>
            <Canvas camera={{ position: [0, 0, 100], fov: 15 }}>
                <color attach="background" args={[config.colors.black]} />

                <Suspense fallback={<Loader />}>
                    <group scale={size === "small" ? 0.5 : 1}>
                        <mesh position={[0, 0, -2]}>
                            <planeGeometry
                                args={[
                                    window.innerWidth / 75,
                                    window.innerHeight / 75,
                                    1,
                                ]}
                            />
                            <meshBasicMaterial>
                                <GradientTexture
                                    stops={[0, 1]} // As many stops as you want
                                    colors={[
                                        config.colors.themeMain,
                                        config.colors.themeMain2,
                                    ]} // Colors need to match the number of stops
                                    size={1024} // Size is optional, default = 1024
                                />
                            </meshBasicMaterial>
                        </mesh>

                        <ScrollControls pages={4} infinite>
                            <Rig rotation={[0, 0, 0.15]}>
                                <group scale={0.7}>
                                    <Ball noExplode />
                                    <group scale={0.7}>
                                        <Icosahedron args={[1, 0]}>
                                            <meshPhysicalMaterial
                                                metalness={0}
                                                roughness={0}
                                                color={config.colors.themeDark}
                                                args={[
                                                    {
                                                        metalness: 0,
                                                        roughness: 0,
                                                    },
                                                ]}
                                            />
                                        </Icosahedron>
                                    </group>

                                    <Carousel
                                        state={props.state}
                                        dispatch={props.dispatch}
                                    />
                                </group>
                            </Rig>
                        </ScrollControls>
                    </group>
                    <Environment preset="dawn" />
                </Suspense>
            </Canvas>
        </div>
    )
}

function Rig(props) {
    const ref = useRef()
    const scroll = useScroll()
    useFrame((state, delta) => {
        ref.current.rotation.y = -scroll.offset * (Math.PI * 2) // Rotate contents
        state.events.update() // Raycasts every frame rather than on pointer-move
        easing.damp3(
            state.camera.position,
            [-state.pointer.x * 2, state.pointer.y + 1.5, 10],
            0.3,
            delta
        ) // Move camera
        state.camera.lookAt(0, 0, 0) // Look at center
    })
    return <group ref={ref} {...props} />
}

function Carousel({ radius = 1.9, count = 6, state, dispatch }) {
    return Array.from({ length: count }, (_, i) => (
        <group key={i}>
            <Card
                state={state}
                dispatch={dispatch}
                url={`/work/${Math.floor(i % 10) + 1}.png`}
                position={[
                    Math.sin((i / count) * Math.PI * 2) * radius,
                    0,
                    Math.cos((i / count) * Math.PI * 2) * radius,
                ]}
                rotation={[0, Math.PI + (i / count) * Math.PI * 2, 0]}
            />
        </group>
    ))
}

const details = {
    "/work/1.png": {
        title: "Visual Elements Modulator",
        descr: `A custom designed space planner for Visual Element's "Seven Point" brand. Allowing customers to design and layout "The Module System" in a virtual 3D space. Seamlessly allowing generation of purchase orders and an admin backend allowing product management. `,
        link: "https://modulator.sevenpointinteriors.com/",
    },
    "/work/2.png": {
        title: "Stackabl",
        descr: `A simple configurator seamlessly integrates the designer into the process. 
        Its easy-to- use interface allows users to turn high quality offcuts into custom furniture according to their choices, 
        from colors and patterns to dimensions and densities.
         Real-time visualizations in 2D and 3D as well as instant pricing feedback make for an 
         intuitive and transparent experience that frees the imagination and opens doors to a world of possibilities. `,
        link: "https://www.stackabl.shop/2021/loungechair",
    },
    "/work/3.png": {
        title: "jimy dawn",
        descr: `
        In a project undertaken for Toronto/B.C. writer jimy dawn, 
        Inspired by a dream I delved into visually and musicaly 
        exploring profound themes surrounding death, the afterlife, 
        and the possibilty of eternal nothingness.
         Through visual mediums, we navigated the intricate landscapes of 
         existential questions, pondering the mysteries that lie beyond mortal existence. 
         With jimy dawn's enigmatic narratives, we sought to unravel the enigmatic nature of life's ultimate 
         journey and contemplate the possibility of a empty void beyond. 
         A poem in the visual. 
        
          `,
        link: "https://www.jimydawn.ca",
    },
    "/work/4.png": {
        title: "Prospecer",
        descr: `
        Prospecer is the operating system for Interior Design. 
   
        Countless detailed decisions and hundreds of billion dollars in product are 
        selected by interior design studios in NA every year. Prospecer, a fully integrated 
        vertical SaaS is establishing itself as the industry leader to support these highly influential decision makers. 
        By connecting designers with their clients, suppliers and contractors in one place, 
        Prospecer introduces rigor and consistency to solve the pain of project management, 
        and product documentation from specification to procurement.
          `,
        link: "https://prospecer.com",
    },

    "/work/5.png": {
        title: "Atomic Design",
        descr: `
        The project involved the creation of a custom digital presence for Atomic Design, 
        a boutique specializing in authentic 20th Century Art & Design. With meticulous 
        attention to client needs, the platform was crafted to include a seamless shopping cart integration, 
        and easy to use CMS,
        enabling Atomic Design to expand its reach to a diverse group of consumers and 
        collectors interested in 20th Century Art & Design. This bespoke solution enhanced 
        Atomic Design's online visibility and facilitated streamlined transactions, 
        fostering a more engaging and accessible experience for art enthusiasts and collectors alike.
          `,
        link: "https://atomicdesign.ca",
    },
    "/work/6.png": {
        title: "Common Good Studio",
        descr: `
        Crafted with the belief that design should never overshadow its own essence,
         the website for Common Good Studio, an award-winning interior design studio based in Toronto, 
         embodies minimalism at its finest. 
         Through simplicity and elegance, the website serves as a pristine canvas, 
         allowing the inherent beauty of Common Good Studio's work to shine unencumbered. 
         Its minimalist design ethos captures the studio's commitment to refined aesthetics,
          ensuring that visitors are captivated by the sheer brilliance of their portfolio, 
          unadorned yet strikingly beautiful.
          `,
        link: "https://www.commongoodstudio.ca",
    },
}

function Detail({ image, title, descr, link, onClose }) {
    const size = useResizer()
    return (
        <div
            style={{
                position: "fixed",
                zIndex: 999999,
                bottom: "0",
                left: "0",
                height: "350px",
                width: "100vw",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1);",
                backgroundColor: config.colors.black,
            }}
        >
            <div
                style={{
                    display: "flex",
                    gap: 15,
                    padding: 15,
                    paddingTop: 30,
                    color: config.colors.white,
                }}
            >
                {size !== "small" && (
                    <div>
                        <div
                            style={{
                                cursor: "pointer",
                                marginTop: "-25px",
                                paddingBottom: "10px",
                            }}
                            onClick={onClose}
                        >
                            [X]
                        </div>
                        <img
                            style={{ transform: "scaleX(-1)", width: "40vw" }}
                            src={image}
                            alt={image}
                        />
                    </div>
                )}
                {size === "small" && (
                    <div
                        style={{
                            cursor: "pointer",
                            marginTop: "-25px",
                            paddingBottom: "10px",
                        }}
                        onClick={onClose}
                    >
                        [X]
                    </div>
                )}

                <div style={{ height: "305px", overflow: "scroll" }}>
                    <h2>{title}</h2>
                    <div>{descr}</div>
                    <br />
                    <br />
                    <div>
                        <a target="_blank" rel="noreferrer" href={link}>
                            {link}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Card({ url, state, dispatch, ...props }) {
    const ref = useRef()
    const [hovered, hover] = useState(false)
    const [detail, setDetail] = useState(null)
    const pointerOver = (e) => (e.stopPropagation(), hover(true))
    const pointerOut = () => hover(false)
    const pointerDown = (e) => {
        e.stopPropagation()
        const data = details[url]
        console.log(data)
        dispatch({
            type: "modal",
            payload: (
                <Detail
                    image={url}
                    title={data.title}
                    descr={data.descr}
                    link={data.link}
                    onClose={() => {
                        dispatch({ type: "modal", payload: null })
                    }}
                />
            ),
        })
    }
    useFrame((state, delta) => {
        easing.damp3(ref.current.scale, hovered ? 1.15 : 1, 0.1, delta)
        easing.damp(
            ref.current.material,
            "radius",
            hovered ? 0.25 : 0.1,
            0.2,
            delta
        )
        easing.damp(ref.current.material, "zoom", hovered ? 1 : 1.2, 0.1, delta)
    })
    return (
        <>
            <Image
                ref={ref}
                url={url}
                scale={[1.8, 1]}
                transparent
                side={THREE.DoubleSide}
                onPointerOver={pointerOver}
                onPointerOut={pointerOut}
                onClick={pointerDown}
                {...props}
            >
                <bentPlaneGeometry args={[0.18, 1.8, 1, 20, 20]} />
            </Image>

            {detail && <>{detail}</>}
        </>
    )
}
