import { useReducer } from "react"
import { config } from "../config"
import useResizer from "./hooks/Resizer"

export function Contact() {
    const size = useResizer()
    return (
        <div
            style={{
                textAlign: "center",
                fontSize: size == "small" ? "15vw" : "5vw",
                paddingTop: "150px",
                color: config.colors.white,
            }}
        >
            <div>DON'T BE SHY 🙈</div>
            <div
                style={{
                    display: "flex",
                    flexDirection: size === "small" ? "column" : "row",
                    fontSize: size == "small" ? "5vw" : "3vw",
                    gap: "20px",
                    width: "100%",
                    justifyContent: "space-evenly",
                    paddingTop: " 20vh",
                }}
            >
                <div>
                    <a href="tel:+14167254707">416.725.4707</a>
                </div>
                <div>
                    <a href="mailto:will@wehsoftware.com">
                        will@wehsoftware.com
                    </a>
                </div>
            </div>
        </div>
    )
}
