/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 public/3D/explodedBall.glb -o src/components/Ball.jsx -r public/3D -k 
*/

import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { useExplode } from "./hooks/useExplode"

export function Ball(props) {
    const { nodes, materials } = useGLTF("/3D/explodedBall.glb")

    const group = useRef()

    useExplode(group, {
        distance: 5,
        enableRotation: true,
        beginPoint: props.noExplode ? -1 : 0.6,
    })

    return (
        <group {...props} ref={group} dispose={null}>
            <mesh
                name="origin"
                geometry={nodes.origin.geometry}
                material={materials["Material.001"]}
            />
            <mesh
                name="Sphere_cell"
                geometry={nodes.Sphere_cell.geometry}
                material={materials["Material.001"]}
                position={[-0.706, -0.435, -0.389]}
            />
            <mesh
                name="Sphere_cell001"
                geometry={nodes.Sphere_cell001.geometry}
                material={materials["Material.001"]}
                position={[0.063, 0.905, 0.347]}
            />
            <mesh
                name="Sphere_cell002"
                geometry={nodes.Sphere_cell002.geometry}
                material={materials["Material.001"]}
                position={[0.776, 0.484, 0.034]}
            />
            <mesh
                name="Sphere_cell003"
                geometry={nodes.Sphere_cell003.geometry}
                material={materials["Material.001"]}
                position={[0.562, 0.399, -0.616]}
            />
            <mesh
                name="Sphere_cell004"
                geometry={nodes.Sphere_cell004.geometry}
                material={materials["Material.001"]}
                position={[0.105, 0.248, -0.882]}
            />
            <mesh
                name="Sphere_cell005"
                geometry={nodes.Sphere_cell005.geometry}
                material={materials["Material.001"]}
                position={[-0.073, 0.881, 0.396]}
            />
            <mesh
                name="Sphere_cell006"
                geometry={nodes.Sphere_cell006.geometry}
                material={materials["Material.001"]}
                position={[0.529, 0.781, -0.082]}
            />
            <mesh
                name="Sphere_cell007"
                geometry={nodes.Sphere_cell007.geometry}
                material={materials["Material.001"]}
                position={[0.781, -0.003, -0.503]}
            />
            <mesh
                name="Sphere_cell008"
                geometry={nodes.Sphere_cell008.geometry}
                material={materials["Material.001"]}
                position={[0.386, -0.019, -0.848]}
            />
            <mesh
                name="Sphere_cell009"
                geometry={nodes.Sphere_cell009.geometry}
                material={materials["Material.001"]}
                position={[0.796, -0.317, -0.372]}
            />
            <mesh
                name="Sphere_cell010"
                geometry={nodes.Sphere_cell010.geometry}
                material={materials["Material.001"]}
                position={[0.033, -0.314, -0.882]}
            />
            <mesh
                name="Sphere_cell011"
                geometry={nodes.Sphere_cell011.geometry}
                material={materials["Material.001"]}
                position={[0.571, -0.158, 0.722]}
            />
            <mesh
                name="Sphere_cell012"
                geometry={nodes.Sphere_cell012.geometry}
                material={materials["Material.001"]}
                position={[0.049, -0.89, -0.321]}
            />
            <mesh
                name="Sphere_cell013"
                geometry={nodes.Sphere_cell013.geometry}
                material={materials["Material.001"]}
                position={[0.273, -0.803, 0.401]}
            />
            <mesh
                name="Sphere_cell014"
                geometry={nodes.Sphere_cell014.geometry}
                material={materials["Material.001"]}
                position={[0.618, 0.124, 0.699]}
            />
            <mesh
                name="Sphere_cell015"
                geometry={nodes.Sphere_cell015.geometry}
                material={materials["Material.001"]}
                position={[0.881, -0.29, -0.109]}
            />
            <mesh
                name="Sphere_cell016"
                geometry={nodes.Sphere_cell016.geometry}
                material={materials["Material.001"]}
                position={[-0.625, 0.235, 0.649]}
            />
            <mesh
                name="Sphere_cell017"
                geometry={nodes.Sphere_cell017.geometry}
                material={materials["Material.001"]}
                position={[-0.562, -0.374, 0.635]}
            />
            <mesh
                name="Sphere_cell018"
                geometry={nodes.Sphere_cell018.geometry}
                material={materials["Material.001"]}
                position={[-0.771, 0.041, -0.508]}
            />
            <mesh
                name="Sphere_cell019"
                geometry={nodes.Sphere_cell019.geometry}
                material={materials["Material.001"]}
                position={[-0.82, -0.031, 0.441]}
            />
            <mesh
                name="Sphere_cell020"
                geometry={nodes.Sphere_cell020.geometry}
                material={materials["Material.001"]}
                position={[0.798, 0.561, 0.009]}
            />
            <mesh
                name="Sphere_cell021"
                geometry={nodes.Sphere_cell021.geometry}
                material={materials["Material.001"]}
                position={[0.256, 0.563, 0.707]}
            />
            <mesh
                name="Sphere_cell022"
                geometry={nodes.Sphere_cell022.geometry}
                material={materials["Material.001"]}
                position={[-0.255, 0.199, 0.874]}
            />
            <mesh
                name="Sphere_cell023"
                geometry={nodes.Sphere_cell023.geometry}
                material={materials["Material.001"]}
                position={[-0.049, -0.579, -0.788]}
            />
            <mesh
                name="Sphere_cell024"
                geometry={nodes.Sphere_cell024.geometry}
                material={materials["Material.001"]}
                position={[-0.762, -0.445, 0.294]}
            />
            <mesh
                name="Sphere_cell025"
                geometry={nodes.Sphere_cell025.geometry}
                material={materials["Material.001"]}
                position={[-0.186, -0.916, 0.078]}
            />
            <mesh
                name="Sphere_cell026"
                geometry={nodes.Sphere_cell026.geometry}
                material={materials["Material.001"]}
                position={[0.381, -0.502, 0.695]}
            />
            <mesh
                name="Sphere_cell027"
                geometry={nodes.Sphere_cell027.geometry}
                material={materials["Material.001"]}
                position={[-0.751, 0.51, 0.177]}
            />
            <mesh
                name="Sphere_cell028"
                geometry={nodes.Sphere_cell028.geometry}
                material={materials["Material.001"]}
                position={[-0.409, 0.843, -0.079]}
            />
            <mesh
                name="Sphere_cell029"
                geometry={nodes.Sphere_cell029.geometry}
                material={materials["Material.001"]}
                position={[0.417, -0.589, -0.612]}
            />
            <mesh
                name="Sphere_cell030"
                geometry={nodes.Sphere_cell030.geometry}
                material={materials["Material.001"]}
                position={[0.136, -0.966, 0.117]}
            />
            <mesh
                name="Sphere_cell031"
                geometry={nodes.Sphere_cell031.geometry}
                material={materials["Material.001"]}
                position={[0.02, 0.174, 0.918]}
            />
            <mesh
                name="Sphere_cell032"
                geometry={nodes.Sphere_cell032.geometry}
                material={materials["Material.001"]}
                position={[0.145, 0.764, 0.543]}
            />
            <mesh
                name="Sphere_cell033"
                geometry={nodes.Sphere_cell033.geometry}
                material={materials["Material.001"]}
                position={[0.074, 0.625, -0.681]}
            />
            <mesh
                name="Sphere_cell034"
                geometry={nodes.Sphere_cell034.geometry}
                material={materials["Material.001"]}
                position={[-0.166, 0.94, 0.065]}
            />
            <mesh
                name="Sphere_cell035"
                geometry={nodes.Sphere_cell035.geometry}
                material={materials["Material.001"]}
                position={[0.394, 0.655, 0.562]}
            />
            <mesh
                name="Sphere_cell036"
                geometry={nodes.Sphere_cell036.geometry}
                material={materials["Material.001"]}
                position={[0.777, 0.353, 0.394]}
            />
            <mesh
                name="Sphere_cell037"
                geometry={nodes.Sphere_cell037.geometry}
                material={materials["Material.001"]}
                position={[0.178, 0.903, 0.24]}
            />
            <mesh
                name="Sphere_cell038"
                geometry={nodes.Sphere_cell038.geometry}
                material={materials["Material.001"]}
                position={[-0.252, -0.695, 0.577]}
            />
            <mesh
                name="Sphere_cell039"
                geometry={nodes.Sphere_cell039.geometry}
                material={materials["Material.001"]}
                position={[0.376, -0.867, 0.075]}
            />
            <mesh
                name="Sphere_cell040"
                geometry={nodes.Sphere_cell040.geometry}
                material={materials["Material.001"]}
                position={[-0.148, 0.878, 0.372]}
            />
            <mesh
                name="Sphere_cell041"
                geometry={nodes.Sphere_cell041.geometry}
                material={materials["Material.001"]}
                position={[0.573, 0.4, 0.64]}
            />
            <mesh
                name="Sphere_cell042"
                geometry={nodes.Sphere_cell042.geometry}
                material={materials["Material.001"]}
                position={[0.525, 0.751, -0.22]}
            />
            <mesh
                name="Sphere_cell043"
                geometry={nodes.Sphere_cell043.geometry}
                material={materials["Material.001"]}
                position={[0.861, -0.149, 0.301]}
            />
            <mesh
                name="Sphere_cell044"
                geometry={nodes.Sphere_cell044.geometry}
                material={materials["Material.001"]}
                position={[-0.088, -0.935, -0.155]}
            />
            <mesh
                name="Sphere_cell045"
                geometry={nodes.Sphere_cell045.geometry}
                material={materials["Material.001"]}
                position={[-0.502, 0.728, -0.289]}
            />
            <mesh
                name="Sphere_cell046"
                geometry={nodes.Sphere_cell046.geometry}
                material={materials["Material.001"]}
                position={[-0.231, -0.589, -0.682]}
            />
            <mesh
                name="Sphere_cell047"
                geometry={nodes.Sphere_cell047.geometry}
                material={materials["Material.001"]}
                position={[0.176, 0.932, -0.034]}
            />
            <mesh
                name="Sphere_cell048"
                geometry={nodes.Sphere_cell048.geometry}
                material={materials["Material.001"]}
                position={[0.595, -0.703, 0.203]}
            />
            <mesh
                name="Sphere_cell049"
                geometry={nodes.Sphere_cell049.geometry}
                material={materials["Material.001"]}
                position={[0.504, -0.298, -0.726]}
            />
            <mesh
                name="Sphere_cell050"
                geometry={nodes.Sphere_cell050.geometry}
                material={materials["Material.001"]}
                position={[0.625, 0.665, 0.253]}
            />
            <mesh
                name="Sphere_cell051"
                geometry={nodes.Sphere_cell051.geometry}
                material={materials["Material.001"]}
                position={[-0.088, -0.958, 0.158]}
            />
            <mesh
                name="Sphere_cell052"
                geometry={nodes.Sphere_cell052.geometry}
                material={materials["Material.001"]}
                position={[0.199, -0.521, -0.757]}
            />
            <mesh
                name="Sphere_cell053"
                geometry={nodes.Sphere_cell053.geometry}
                material={materials["Material.001"]}
                position={[-0.411, 0.774, 0.354]}
            />
            <mesh
                name="Sphere_cell054"
                geometry={nodes.Sphere_cell054.geometry}
                material={materials["Material.001"]}
                position={[-0.04, 0.942, 0.143]}
            />
            <mesh
                name="Sphere_cell055"
                geometry={nodes.Sphere_cell055.geometry}
                material={materials["Material.001"]}
                position={[-0.196, 0.846, 0.302]}
            />
            <mesh
                name="Sphere_cell056"
                geometry={nodes.Sphere_cell056.geometry}
                material={materials["Material.001"]}
                position={[0.032, -0.774, -0.529]}
            />
            <mesh
                name="Sphere_cell057"
                geometry={nodes.Sphere_cell057.geometry}
                material={materials["Material.001"]}
                position={[-0.305, -0.29, 0.833]}
            />
            <mesh
                name="Sphere_cell058"
                geometry={nodes.Sphere_cell058.geometry}
                material={materials["Material.001"]}
                position={[-0.287, 0.625, -0.627]}
            />
            <mesh
                name="Sphere_cell059"
                geometry={nodes.Sphere_cell059.geometry}
                material={materials["Material.001"]}
                position={[-0.398, 0.848, 0.141]}
            />
            <mesh
                name="Sphere_cell060"
                geometry={nodes.Sphere_cell060.geometry}
                material={materials["Material.001"]}
                position={[-0.106, 0.898, -0.207]}
            />
            <mesh
                name="Sphere_cell061"
                geometry={nodes.Sphere_cell061.geometry}
                material={materials["Material.001"]}
                position={[-0.561, -0.706, 0.263]}
            />
            <mesh
                name="Sphere_cell062"
                geometry={nodes.Sphere_cell062.geometry}
                material={materials["Material.001"]}
                position={[-0.599, -0.061, 0.714]}
            />
            <mesh
                name="Sphere_cell063"
                geometry={nodes.Sphere_cell063.geometry}
                material={materials["Material.001"]}
                position={[-0.203, -0.859, 0.331]}
            />
            <mesh
                name="Sphere_cell064"
                geometry={nodes.Sphere_cell064.geometry}
                material={materials["Material.001"]}
                position={[0.087, 0.401, 0.843]}
            />
            <mesh
                name="Sphere_cell065"
                geometry={nodes.Sphere_cell065.geometry}
                material={materials["Material.001"]}
                position={[-0.909, 0.055, 0.012]}
            />
            <mesh
                name="Sphere_cell066"
                geometry={nodes.Sphere_cell066.geometry}
                material={materials["Material.001"]}
                position={[-0.003, 0.851, 0.378]}
            />
            <mesh
                name="Sphere_cell067"
                geometry={nodes.Sphere_cell067.geometry}
                material={materials["Material.001"]}
                position={[-0.397, 0.664, 0.56]}
            />
            <mesh
                name="Sphere_cell068"
                geometry={nodes.Sphere_cell068.geometry}
                material={materials["Material.001"]}
                position={[-0.079, -0.526, 0.775]}
            />
            <mesh
                name="Sphere_cell069"
                geometry={nodes.Sphere_cell069.geometry}
                material={materials["Material.001"]}
                position={[-0.252, -0.185, -0.864]}
            />
            <mesh
                name="Sphere_cell070"
                geometry={nodes.Sphere_cell070.geometry}
                material={materials["Material.001"]}
                position={[0.788, -0.476, 0.178]}
            />
            <mesh
                name="Sphere_cell071"
                geometry={nodes.Sphere_cell071.geometry}
                material={materials["Material.001"]}
                position={[0.359, -0.863, -0.132]}
            />
            <mesh
                name="Sphere_cell072"
                geometry={nodes.Sphere_cell072.geometry}
                material={materials["Material.001"]}
                position={[-0.528, -0.38, 0.726]}
            />
            <mesh
                name="Sphere_cell073"
                geometry={nodes.Sphere_cell073.geometry}
                material={materials["Material.001"]}
                position={[-0.476, -0.022, -0.785]}
            />
            <mesh
                name="Sphere_cell074"
                geometry={nodes.Sphere_cell074.geometry}
                material={materials["Material.001"]}
                position={[0.862, 0.282, -0.162]}
            />
            <mesh
                name="Sphere_cell075"
                geometry={nodes.Sphere_cell075.geometry}
                material={materials["Material.001"]}
                position={[-0.864, -0.159, -0.329]}
            />
            <mesh
                name="Sphere_cell076"
                geometry={nodes.Sphere_cell076.geometry}
                material={materials["Material.001"]}
                position={[-0.657, -0.626, -0.201]}
            />
            <mesh
                name="Sphere_cell077"
                geometry={nodes.Sphere_cell077.geometry}
                material={materials["Material.001"]}
                position={[-0.499, -0.789, -0.106]}
            />
            <mesh
                name="Sphere_cell078"
                geometry={nodes.Sphere_cell078.geometry}
                material={materials["Material.001"]}
                position={[-0.538, 0.493, 0.584]}
            />
            <mesh
                name="Sphere_cell079"
                geometry={nodes.Sphere_cell079.geometry}
                material={materials["Material.001"]}
                position={[0.475, 0.811, 0.107]}
            />
            <mesh
                name="Sphere_cell080"
                geometry={nodes.Sphere_cell080.geometry}
                material={materials["Material.001"]}
                position={[0.615, -0.576, -0.407]}
            />
            <mesh
                name="Sphere_cell081"
                geometry={nodes.Sphere_cell081.geometry}
                material={materials["Material.001"]}
                position={[0.643, 0.544, 0.417]}
            />
            <mesh
                name="Sphere_cell082"
                geometry={nodes.Sphere_cell082.geometry}
                material={materials["Material.001"]}
                position={[-0.334, -0.837, -0.237]}
            />
            <mesh
                name="Sphere_cell083"
                geometry={nodes.Sphere_cell083.geometry}
                material={materials["Material.001"]}
                position={[0.183, -0.654, 0.654]}
            />
            <mesh
                name="Sphere_cell084"
                geometry={nodes.Sphere_cell084.geometry}
                material={materials["Material.001"]}
                position={[-0.18, 0.629, 0.668]}
            />
            <mesh
                name="Sphere_cell085"
                geometry={nodes.Sphere_cell085.geometry}
                material={materials["Material.001"]}
                position={[0.41, 0.75, -0.391]}
            />
            <mesh
                name="Sphere_cell086"
                geometry={nodes.Sphere_cell086.geometry}
                material={materials["Material.001"]}
                position={[0.294, -0.191, 0.864]}
            />
            <mesh
                name="Sphere_cell087"
                geometry={nodes.Sphere_cell087.geometry}
                material={materials["Material.001"]}
                position={[-0.01, -0.206, 0.912]}
            />
            <mesh
                name="Sphere_cell088"
                geometry={nodes.Sphere_cell088.geometry}
                material={materials["Material.001"]}
                position={[0.254, -0.863, -0.279]}
            />
            <mesh
                name="Sphere_cell089"
                geometry={nodes.Sphere_cell089.geometry}
                material={materials["Material.001"]}
                position={[0.601, -0.521, 0.505]}
            />
            <mesh
                name="Sphere_cell090"
                geometry={nodes.Sphere_cell090.geometry}
                material={materials["Material.001"]}
                position={[0.821, 0.134, 0.422]}
            />
            <mesh
                name="Sphere_cell091"
                geometry={nodes.Sphere_cell091.geometry}
                material={materials["Material.001"]}
                position={[-0.239, 0.912, 0.293]}
            />
            <mesh
                name="Sphere_cell092"
                geometry={nodes.Sphere_cell092.geometry}
                material={materials["Material.001"]}
                position={[-0.175, 0.934, -0.087]}
            />
            <mesh
                name="Sphere_cell093"
                geometry={nodes.Sphere_cell093.geometry}
                material={materials["Material.001"]}
                position={[0.662, -0.673, -0.084]}
            />
            <mesh
                name="Sphere_cell094"
                geometry={nodes.Sphere_cell094.geometry}
                material={materials["Material.001"]}
                position={[0.386, 0.28, 0.809]}
            />
            <mesh
                name="Sphere_cell095"
                geometry={nodes.Sphere_cell095.geometry}
                material={materials["Material.001"]}
                position={[0.07, 0.862, -0.379]}
            />
            <mesh
                name="Sphere_cell096"
                geometry={nodes.Sphere_cell096.geometry}
                material={materials["Material.001"]}
                position={[-0.036, 0.952, -0.195]}
            />
            <mesh
                name="Sphere_cell097"
                geometry={nodes.Sphere_cell097.geometry}
                material={materials["Material.001"]}
                position={[0.115, -0.901, 0.118]}
            />
            <mesh
                name="Sphere_cell098"
                geometry={nodes.Sphere_cell098.geometry}
                material={materials["Material.001"]}
                position={[-0.617, 0.4, -0.549]}
            />
            <mesh
                name="Sphere_cell099"
                geometry={nodes.Sphere_cell099.geometry}
                material={materials["Material.001"]}
                position={[0.379, 0.803, 0.334]}
            />
        </group>
    )
}

useGLTF.preload("/explodedBall.glb")
