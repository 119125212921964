/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react"

import Logo from "../images/logo.svg"
import { config } from "../config"
import { NavLink } from "react-router-dom"

function TopLogo(props) {
    return (
        <div
            css={css`
                font-size: 20pt;
                font-family: bold;
                color: #efefef;
            `}
        >
            <img
                css={css`
                    width: 50px;
                `}
                src={Logo}
                alt="logo"
            />
        </div>
    )
}

export function Header(props) {
    return (
        <div
            css={css`
                position: fixed;
                top: 0;
                left: 0;
                z-index: 999999;
            `}
        >
            <div
                css={css`
                    width: calc(100vw - 20px);
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                    margin-left: 10px;
                    margin-right: 10px;

                    font-family: bold;
                    color: ${config.colors.white};
                `}
            >
                <div
                    css={css`
                        width: 20vw;
                    `}
                >
                    <NavLink style={{ textDecoration: "none" }} to="/">
                        WEH Software
                    </NavLink>
                </div>

                <div>
                    <TopLogo />
                </div>
                <div
                    css={css`
                        width: 20vw;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            justify-content: flex-end;
                        `}
                    >
                        <a
                            css={css`
                                background-color: ${config.colors.themeDark};
                                padding: 10px;
                                padding-left: 20px;
                                padding-right: 20px;
                                border-radius: 40px;
                                color: #efefef;
                                text-decoration: none;
                            `}
                            href="/contact"
                        >
                            Contact
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
