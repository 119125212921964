import { Box, Edges, RoundedBox, Text, Trail } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import { useRef } from "react"

export default function Loader(props) {
    const ref = useRef()
    const gref = useRef()
    const bref = useRef()
    const groupref = useRef()
    useFrame(() => {
        ref.current.rotation.z = ref.current.rotation.z + 0.01
        ref.current.rotation.x = ref.current.rotation.x + 0.02

        gref.current.rotation.z = gref.current.rotation.z - 0.02
        gref.current.rotation.x = gref.current.rotation.x + 0.03

        bref.current.rotation.z = bref.current.rotation.z + 0.02
        bref.current.rotation.x = bref.current.rotation.x - 0.01

        groupref.current.rotation.x = groupref.current.rotation.x + 0.05
        //groupref.current.rotation.y = groupref.current.rotation.y + 0.03
        groupref.current.rotation.z = groupref.current.rotation.z + 0.04

        //console.log(ref)
    })

    return (
        <>
            <group ref={groupref}>
                <ambientLight />
                <RoundedBox
                    position={[0, 0, 0]}
                    ref={ref}
                    args={[0.1, 0.1, 0.1]} // Args for the buffer geometry
                    radius={0.04}
                >
                    <meshPhysicalMaterial
                        color="#b2ece1"
                        roughness={0.0}
                        thickness={0.5}
                        metalness={0.4}
                    />
                </RoundedBox>
                <RoundedBox
                    position={[0, 0, 0.2]}
                    ref={gref}
                    args={[0.1, 0.1, 0.1]} // Args for the buffer geometry
                    radius={0.02}
                >
                    <meshPhysicalMaterial
                        color="#477890"
                        roughness={0.0}
                        thickness={0.5}
                        metalness={0.4}
                    />
                </RoundedBox>
                <RoundedBox
                    position={[-0.2, 0.4, 0.2]}
                    ref={gref}
                    args={[0.1, 0.1, 0.1]} // Args for the buffer geometry
                    radius={0.02}
                >
                    <meshPhysicalMaterial
                        color="#e0777d"
                        roughness={0.0}
                        thickness={0.5}
                        metalness={0.4}
                    />
                </RoundedBox>
                <RoundedBox
                    position={[0, 0, -0.2]}
                    ref={bref}
                    args={[0.1, 0.1, 0.1]} // Args for the buffer geometry
                    radius={0.02}
                >
                    <meshPhysicalMaterial
                        color="#8e3b46"
                        roughness={0.0}
                        metalness={0.4}
                        thickness={0.5}
                    />
                </RoundedBox>
                <RoundedBox
                    position={[-0.2, -0.4, -0.2]}
                    ref={bref}
                    args={[0.1, 0.1, 0.1]} // Args for the buffer geometry
                    radius={0.02}
                >
                    <meshPhysicalMaterial
                        color="#86ba90"
                        roughness={0.0}
                        thickness={0.5}
                        metalness={0.4}
                    />
                </RoundedBox>
            </group>
            <Text
                position={[-1, -0.5, 0]}
                scale={[0.1, 0.1, 1]}
                rotation={[0, Math.PI / 2, 0]}
            >
                Loading
            </Text>
        </>
    )
}
