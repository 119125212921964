import {
    Box,
    Float,
    GradientTexture,
    Icosahedron,
    MeshDistortMaterial,
    MeshWobbleMaterial,
    useCursor,
    useScroll,
} from "@react-three/drei"
import { useFrame, useLoader } from "@react-three/fiber"
import React, { useRef, useState } from "react"
import * as THREE from "three"
import { BamShader } from "./shaders/Bam"
import { Ball } from "./Ball"
import { config } from "../config"
import { RGBELoader } from "three-stdlib"
import { useNavigate } from "react-router-dom"

function scaleUp(item) {
    if (item.current.scale.x === 0.001) {
        item.current.scale.set(0.04, 0.04, 0.04)
    }
    if (item.current.scale.x < 1) {
        const nc = item.current.scale.x + 0.03
        item.current.scale.set(nc, nc, nc)
    }
}

function scaleDown(item) {
    if (item.current.scale.x === 0.001) {
        item.current.scale.set(0.04, 0.04, 0.04)
    }
    if (item.current.scale.x > 0.4) {
        const t = item.current.scale.x - 0.03
        const nc = -0.02 * t * t + (1 + 0.03) * t
        item.current.scale.set(nc, nc, nc)
    }
}

function vanishItem(item) {
    item.current.scale.set(0.001, 0.001, 0.001)
}

export default function Orb(props) {
    let navigate = useNavigate()
    const heart = useRef()
    const box = useRef()
    const ico = useRef()
    const ball = useRef()
    const changes = { state: "1" }

    const multi = 150
    const values = { running: "off", counter: 100 }
    const scroll = useScroll()

    // Way too slow. Clean this up
    useFrame(({ clock, camera }) => {
        if (changes.state === 1) {
            scaleUp(box)
            scaleDown(ico)
            scaleDown(ball)
        } else if (changes.state === 2) {
            //scaleDown(box)
            scaleUp(ico)
            scaleDown(ball)
        } else if (changes.state === 3) {
            //scaleDown(box)
            //scaleDown(ico)
            scaleUp(ball)
        }

        if (scroll.offset < 0.1) {
            changes.state = 1
            if (props.state.page !== 1) {
                props.dispatch({ type: "page", payload: 1 })
            }
        } else if (scroll.offset > 0.1 && scroll.offset < 0.3) {
            changes.state = 2
            if (props.state.page !== 2) {
                props.dispatch({ type: "page", payload: 2 })
            }
        } else if (scroll.offset > 0.3 && scroll.offset < 0.6) {
            changes.state = 3
            if (props.state.page !== 3) {
                props.dispatch({ type: "page", payload: 3 })
            }
        } else if (scroll.offset > 0.94) {
            if (props.state.page !== 5) {
                props.dispatch({ type: "page", payload: 5 })
            }
        } else {
            changes.state = 4
            if (props.state.page !== 4) {
                vanishItem(box)
                vanishItem(ico)
                props.dispatch({ type: "page", payload: 4 })
            }
        }

        const c = scroll.range(0.5, 1) + 0.00001
        camera.position.z = 5 - c * 10
        camera.position.y = 1 - c
        values.counter++
        if (values.counter % multi === 0) {
            values.running = "grow"
        }
        if (values.running === "grow") {
            if (heart.current.scale.x < 0.8) {
                const nc = heart.current.scale.x + 0.006
                heart.current.scale.set(nc, nc, nc)
            } else {
                values.running = "shrink"
            }
        }
        if (values.running === "shrink") {
            if (heart.current.scale.x > 0.7) {
                const nc = heart.current.scale.x - 0.006
                heart.current.scale.set(nc, nc, nc)
            } else {
                values.running = "off"
            }
        }
    })

    return (
        <>
            <Float
                speed={3} // Animation speed, defaults to 1
                rotationIntensity={0} // XYZ rotation intensity, defaults to 1
                floatIntensity={0.1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                floatingRange={[-1, 1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
            >
                <mesh scale={1}>
                    <Ball />
                </mesh>
                <mesh scale={0.7} ref={heart}>
                    <mesh ref={box}>
                        <Box scale={0.9} args={[1, 1]}>
                            <meshPhysicalMaterial
                                metalness={0}
                                roughness={0}
                                color={config.colors.themeDark}
                                args={[{ metalness: 0, roughness: 0 }]}
                            ></meshPhysicalMaterial>
                        </Box>
                    </mesh>
                    <mesh scale={0.5} ref={ico}>
                        <Icosahedron args={[1, 0]}>
                            <meshPhysicalMaterial
                                metalness={0}
                                roughness={0}
                                color={config.colors.themeDark}
                                args={[{ metalness: 0, roughness: 0 }]}
                            />
                        </Icosahedron>
                    </mesh>

                    <mesh scale={0.5} ref={ball}>
                        <Icosahedron args={[1, 20]}>
                            <meshPhysicalMaterial
                                metalness={0}
                                roughness={0}
                                color={config.colors.themeDark}
                                args={[{ metalness: 0, roughness: 0 }]}
                                // side={THREE.DoubleSide}
                            />
                        </Icosahedron>
                    </mesh>
                </mesh>
            </Float>
        </>
    )
}
