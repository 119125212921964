/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useEffect, useState } from "react"
import Portfolio from "./Portfolio"
import { config } from "../config"
import useResizer from "./hooks/Resizer"

function TextBlock(props) {
    const size = useResizer()
    return (
        <div
            className="animate-in"
            css={css`
                position: fixed;
                bottom: ${size === "small" ? "40px" : "150px"};
                left: ${size === "small" ? "20px" : "100px"};
                z-index: 9999;
                width: 350px;
            `}
        >
            <h2
                css={css`
                    font-family: bold;
                    color: ${config.colors.themeDark};
                `}
            >
                {props.titleFirst}
                <span style={{ color: config.colors.white }}>
                    <b>{props.titleSecond}</b>
                </span>
            </h2>
            <div style={{ color: config.colors.white }}>{props.body}</div>
        </div>
    )
}

export function HeroText(props) {
    const [scrollPosition, setScrollPosition] = useState(0)

    return (
        <>
            {props.state.page === 1 && (
                <TextBlock
                    titleFirst="BRING IDEAS TO"
                    titleSecond="LIFE"
                    body="Turning Dreams into Digital Reality: We are your dedicated
        partner in custom design, web, and application development,
        committed to transforming your unique business vision into a
        powerful and thriving digital presence."
                />
            )}
            {props.state.page === 2 && (
                <TextBlock
                    titleFirst="AGILE AND"
                    titleSecond="COLLABORATIVE"
                    body="Partnering with you in perpetual motion to 
                    formulate top-tier solutions aligned with your expectations and budget.
                     We kick off with incremental steps, progressively ascending towards comprehensive success.."
                />
            )}
            {props.state.page === 3 && (
                <TextBlock
                    titleFirst="FULL-STACK"
                    titleSecond="DEVELOPMENT"
                    body="We seamlessly manage every aspect of your project, 
                    from back-end and front-end development to hosting and DevOps.
                     No need to seek multiple vendors; we serve as your comprehensive one-stop shop, 
                    ensuring the full realization of your vision across all phases of application development."
                />
            )}
        </>
    )
}
