import React, { useReducer } from "react"
import logo from "./logo.svg"
import "./App.css"
import Big3DLogo from "./components/Big3DLogo"
import GradLights from "./components/GradLights"
import ShaderGrad from "./components/ShaderGrad"
import { Grad } from "./components/Grad"
import { Header } from "./components/Header"
import { HeroText } from "./components/HeroText"
import { Routes, Route, BrowserRouter as Router } from "react-router-dom"
import { Portfilo } from "./components/Portfilo"
import { About } from "./components/About"
import { Contact } from "./components/Contact"

function reducer(state, action) {
    if (action.type === "page") {
        return {
            ...state,
            page: action.payload,
        }
    }
    if (action.type === "modal") {
        return {
            ...state,
            modal: action.payload,
        }
    }
}

function App() {
    const [state, dispatch] = useReducer(reducer, { page: 1, modal: null })

    return (
        <Router>
            <div>
                <Header />
                {state.modal && state.modal}
                <Routes>
                    <Route
                        path="/portfolio"
                        element={<Portfilo state={state} dispatch={dispatch} />}
                    />
                    <Route
                        path="/about"
                        element={<About state={state} dispatch={dispatch} />}
                    />
                    <Route
                        path="/contact"
                        element={<Contact state={state} dispatch={dispatch} />}
                    />
                    <Route
                        path="*"
                        element={
                            <>
                                <HeroText state={state} dispatch={dispatch} />
                                <Big3DLogo state={state} dispatch={dispatch} />
                            </>
                        }
                    />
                </Routes>
            </div>
        </Router>
    )
}

export default App
