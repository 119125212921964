import * as THREE from "three"
import React, { useRef } from "react"
import { Canvas, useFrame, useThree } from "@react-three/fiber"
import { useLoader } from "@react-three/fiber"
import {
    ScrollControls,
    Scroll,
    GradientTexture,
    useDepthBuffer,
    Environment,
    CameraShake,
} from "@react-three/drei"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { Suspense } from "react"
import Stage from "./Stage"
import Rotate from "../actions/Rotate"

import Orb from "./Orb"
import { MovingSpot } from "./MovingSpotLight"
import Portfolio from "./Portfolio"
import { config } from "../config"
import { Link } from "react-router-dom"
import { Links } from "./Links"
import Loader from "./Loader"

function Model(props) {
    const gltf = useLoader(GLTFLoader, "/3D/" + props.file)

    const scene = gltf.scene

    scene.traverse(function (child) {
        if (child.isMesh) {
            child.castShadow = props.castShadow
            child.receiveShadow = props.receiveShadow
        }
    })

    return (
        <>
            <primitive object={gltf.scene} scale={0.4} />
        </>
    )
}

function WowLighting(props) {
    const depthBuffer = useDepthBuffer({ frames: 1 })

    return (
        <>
            <MovingSpot
                depthBuffer={depthBuffer}
                color="#C24E35"
                position={[3, 3, 2]}
            />
        </>
    )
}

export default function Big3DLogo(props) {
    const mesh = useRef()

    return (
        <div
            style={{
                position: "absolute",
                width: "100vw",
                height: "100vh",
            }}
        >
            <Canvas
                shadows={{ type: THREE.PCFSoftShadowMap }}
                camera={{ fov: 40, position: [0, 1, 5] }}
                id={"mainCanvas"}
            >
                <Suspense fallback={<Loader />}>
                    <color attach="background" args={[config.colors.black]} />
                    <ScrollControls
                        id="scrolldiv"
                        pages={4} // Each page takes 100% of the height of the canvas
                        distance={1} // A factor that increases scroll bar travel (default: 1)
                        damping={0.4} // Friction, higher is faster (default: 4)
                        horizontal={false} // Can also scroll horizontally (default: false)
                        infinite={false} // Can also scroll infinitely (default: false)
                        style={{ visibility: "none" }}
                    >
                        <Stage
                            contactShadow={false}
                            shadows
                            intensity={0.5}
                            preset="soft"
                            makeDefault
                            adjustCamera={false}
                        >
                            <Environment files="/3D/sky.hdr" />

                            <mesh ref={mesh} scale={1} position={[0, 0.2, 0]}>
                                <Orb
                                    state={props.state}
                                    dispatch={props.dispatch}
                                />
                            </mesh>
                            {props.state.page == 5 && (
                                <mesh scale={0.2} position={[0, -0, -2]}>
                                    <Links />
                                </mesh>
                            )}

                            <mesh position={[0, 0, -2]}>
                                <planeGeometry
                                    args={[
                                        window.innerWidth / 75,
                                        window.innerHeight / 75,
                                        1,
                                    ]}
                                />

                                <meshBasicMaterial>
                                    <GradientTexture
                                        stops={[0, 1]} // As many stops as you want
                                        colors={[
                                            config.colors.themeMain,
                                            config.colors.themeMain2,
                                        ]} // Colors need to match the number of stops
                                        size={1024} // Size is optional, default = 1024
                                    />
                                </meshBasicMaterial>
                            </mesh>
                        </Stage>

                        <Scroll>
                            <Rotate mesh={mesh} />
                        </Scroll>
                    </ScrollControls>
                </Suspense>
            </Canvas>
        </div>
    )
}
//
