import { useScroll } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import { useEffect } from "react"

import * as THREE from "three"

export const useExplode = (
    group,
    { distance = 3, enableRotation = true, beginPoint = 0 }
) => {
    useEffect(() => {
        const groupWorldPosition = new THREE.Vector3()
        group.current.getWorldPosition(groupWorldPosition)

        group.current.children.forEach((mesh) => {
            mesh.originalPosition = mesh.position.clone()
            const meshWorldPosition = new THREE.Vector3()
            mesh.getWorldPosition(meshWorldPosition)

            mesh.directionVector = meshWorldPosition
                .clone()
                .sub(groupWorldPosition)
                .normalize()

            mesh.originalRotation = mesh.rotation.clone()
            mesh.targetRotation = new THREE.Euler(
                Math.random() * Math.PI,
                Math.random() * Math.PI,
                Math.random() * Math.PI
            )
        })
    }, [])

    useEffect(() => {
        group.current.children.forEach((mesh) => {
            mesh.targetPosition = mesh.originalPosition
                .clone()
                .add(mesh.directionVector.clone().multiplyScalar(distance))
        })
    }, [distance])

    const scrollData = useScroll()

    useFrame(() => {
        const offset =
            beginPoint === 0
                ? scrollData.offset
                : Math.max(
                      ((scrollData.offset - beginPoint) * 1) / beginPoint,
                      0
                  )

        group.current.children.forEach((mesh) => {
            if (offset < 0.0001) {
                if (mesh.name === "origin") {
                    mesh.visible = true
                } else {
                    mesh.visible = false
                }
            } else {
                if (mesh.name === "origin") {
                    mesh.visible = false
                } else {
                    mesh.visible = true
                }
            }

            mesh.position.x = THREE.MathUtils.lerp(
                mesh.originalPosition.x,
                mesh.targetPosition.x,
                offset // 0 at the beginning and 1 after scroll
            )
            mesh.position.y = THREE.MathUtils.lerp(
                mesh.originalPosition.y,
                mesh.targetPosition.y,
                offset // 0 at the beginning and 1 after scroll
            )
            mesh.position.z = THREE.MathUtils.lerp(
                mesh.originalPosition.z,
                mesh.targetPosition.z,
                offset // 0 at the beginning and 1 after scroll
            )

            if (enableRotation) {
                mesh.rotation.x = THREE.MathUtils.lerp(
                    mesh.originalRotation.x,
                    mesh.targetRotation.x,
                    offset // 0 at the beginning and 1 after scroll
                )
                mesh.rotation.y = THREE.MathUtils.lerp(
                    mesh.originalRotation.y,
                    mesh.targetRotation.y,
                    offset // 0 at the beginning and 1 after scroll
                )
                mesh.rotation.z = THREE.MathUtils.lerp(
                    mesh.originalRotation.z,
                    mesh.targetRotation.z,
                    offset // 0 at the beginning and 1 after scroll
                )
            }
        })
    })
}
